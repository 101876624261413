<template>
  <div class="secondlevelTable">
    <el-dialog title="子订单" :visible.sync="centerdelet" width="80%" :close-on-click-modal="false" :before-close="handleClose">
      <div class="content">
        <el-table :data="tableData" style="width: 100%" highlight-current-row>
          <el-table-column prop="orderId" label="序号" width="50" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="子订单ID" width="180" align="center">
          </el-table-column>
          <el-table-column prop="frameCutImageNum" label="截帧图片数" width="180" align="center">
          </el-table-column>
          <el-table-column prop="successImageNum" label="成功截帧图片数" align="center">
          </el-table-column>
          <el-table-column prop="loseImageNum" label="失败截帧图片数" align="center">
          </el-table-column>
          <el-table-column prop="orderStatus" label="订单状态" align="center">
          </el-table-column>
          <el-table-column prop="opTime" label="订单处理时间" align="center">
          </el-table-column>
          <el-table-column prop="desensitizationFlag" label="订单脱敏状态" align="center">
          </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="scope">
              <el-button style="width: 60px" type="primary" size="small" @click="checkItem(scope.row)">查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="centerdelet = false">取 消</el-button>
        <el-button type="primary" @click="sure()" style="margin-left:40px;">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  components: {},
  props: {},
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      centerdelet: false,
      tableData: [
        {
          etime: "1",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    showDialog(id) {
      this.centerdelet = true;
      this.getDetail(id)
    },
    sure() {
      this.$emit("sureEvent");
      this.centerdelet = false;
    },
    //查看
    checkItem(item) {
      this.$emit("sureEvent", item);
    },
    handleClose() {
       this.centerdelet = false;
    },
    //获取二级表格
    getDetail(id) {
      let { baseUrl } = this;
      console.log(id);
      $.ajax({
        url: `${baseUrl}/frameCutOrder/childList`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {
          id: id,
        },
        success: (data) => {
          console.log(data.data);
          if (data.code == "00000") {
            // this.pageTotal = data.totalCount;
            if (data.data) {
              data.data.map((item, index) => {
                item.opTime = dayjs(item.opTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
              });
              this.tableData = data.data;
            } else {
              this.tableData = [];
            }
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.alertdelet {
  .el-dialog__title {
    margin-left: 10px !important;
  }
  .el-dialog {
    width: 360px !important;
    height: 230px !important;
    border-radius: 8px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 14px;
  }
  .delehint {
    vertical-align: middle;
    margin-left: 10px;
    display: inline-block;
    width: 225px;
    text-align: left;
  }
  .dialog-footer {
    text-align: center;
  }
  .dele_desc {
    margin-top: 20px;
  }
}
</style>