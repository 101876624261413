<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-03-31 16:57:29
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-08-30 11:00:48
-->
<template>
  <div id="cutPhoto">
    <div class="curPhotoBody">
      <!-- <div class="menu_box">
        <el-button type="primary" size="small" @click="addTask">增加任务</el-button>
        <el-button type="primary" size="small" @click="getFirst()">刷新列表</el-button>
      </div> -->
      <search @search="searchList" @add="addTask"></search>
      <div class="tableList">
        <el-table :data="tableData" style="width: 100%" highlight-current-row>
          <el-table-column label="序号" width="50" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="id" label="任务ID" width="150" align="center">
          </el-table-column> -->
          <el-table-column prop="orderId" label="订单ID" width="130" align="center">
          </el-table-column>
          <el-table-column prop="deviceId" label="设备ID" width="130" align="center">
          </el-table-column>
          <el-table-column prop="orderStatus" label="订单状态" align="center">
          </el-table-column>
          <el-table-column prop="channel" label="渠道" align="center">
          </el-table-column>
          <el-table-column prop="frameCutNum" label="截帧数" align="center">
          </el-table-column>
          <el-table-column prop="expireTime" label="订单失效时间" align="center" width="160">
          </el-table-column>
          <el-table-column prop="sendTime" label="订单发送时间" align="center" width="160">
          </el-table-column>
          <el-table-column prop="receiveTime" label="订单接收时间" align="center" width="160">
          </el-table-column>
          <el-table-column prop="issueTime" label="订单下发时间" align="center" width="160">
          </el-table-column>
          <!-- <el-table-column prop="scope" label="任务状态" align="center">
            <template slot-scope="scope">
              <span style="color: #f56c6c" v-if="scope.row.status == 0">未下发</span>
              <span style="color: #67c23a" v-if="scope.row.status == 1">已下发</span>
              <span v-if="scope.row.status == 2">已取消</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="resultReceiveTime" show-overflow-tooltip show width="200" label="订单结果接收时间" align="center">
          </el-table-column>
          <el-table-column prop="" label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button style="width: 60px" type="primary" size="small" @click="checkItem(scope.row)">查看
              </el-button>
              <el-button style="width: 60px" type="primary" size="small" @click="downloadBtn(scope.row)">下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right; padding: 10px; background: #fff">
        <el-pagination @current-change="currentChange" current-page.sync="cpage" background :page-size="pageSize" :total="pageTotal" layout="prev, pager, next">
        </el-pagination>
      </div>
      <el-dialog title="增加任务" @close="cancel" :visible.sync="centerDialogVisible" width="600px" center :close-on-click-modal="closedialog">
        <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="截帧模式" prop="model">
              <el-select v-model="ruleForm.model" placeholder="请选择（随机、等间隔）" style="width: 300px">
                <el-option label="随机" value="1"></el-option>
                <el-option label="间隔" value="2"></el-option>
                <el-option label="输入" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="间隔秒数" v-show="ruleForm.model == 2">
              <el-select v-model="ruleForm.secend" placeholder="请选择秒数间隔" style="width: 300px">
                <el-option label="1s" :value="1"></el-option>
                <el-option label="5s" :value="5"></el-option>
                <el-option label="20s" :value="20"></el-option>
                <el-option label="60s" :value="60"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="截帧数量" prop="photoNum">
              <el-input placeholder="请输入" style="width: 300px" v-model.number="ruleForm.photoNum">
              </el-input>
            </el-form-item>
            <el-form-item label="设备ID" prop="deviceId">
              <el-input placeholder="请输入" clearable style="width: 300px" v-model="ruleForm.deviceId"></el-input>
            </el-form-item>
            <el-form-item label="任务ID" prop="orderId">
              <el-input placeholder="请输入" clearable style="width: 300px" v-model="ruleForm.orderId"></el-input>
            </el-form-item>
            <el-form-item label="Channel" prop="channel">
              <el-input placeholder="请输入" clearable style="width: 300px" v-model="ruleForm.channel"></el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.model != 3" label="截帧时间">
              <!-- <el-date-picker v-model="ruleForm.timeRage" :default-time="['00:00:00', '23:59:59']"
                                type="datetimerange" range-separator="至" start-placeholder="开始日期"
                                value-format="timestamp" end-placeholder="结束日期">
                            </el-date-picker> -->
              <el-input placeholder="开始时间" style="width: 150px" clearable v-model.number="ruleForm.btime"></el-input>
              <el-input placeholder="结束时间" style="width: 150px" clearable v-model.number="ruleForm.stime"></el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.model == 3" label="截帧时间">
              <el-input type="textarea" :rows="4" resize="none" placeholder="请输入" v-model="ruleForm.textarea">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer flooterMenu">
          <el-button @click="cancel" size="small" style="width: 80px">取 消</el-button>
          <el-button type="primary" @click="sendTask" size="small" style="width: 80px">确 定
          </el-button>
        </div>
      </el-dialog>
      <el-dialog title="任务详情" @close="closeDetail" :visible.sync="dialogDetail" width="1080px" center :close-on-click-modal="closedialog">
        <div class="taskDetail">
          <div class="detailTitle">基本信息</div>
          <div class="detailDesc">
            <div class="detailItem">
              <span>任务ID</span>：
              <span>{{ detailObj.id }}</span>
            </div>
            <div class="detailItem">
              <span>设备ID</span>：
              <span>{{ detailObj.deviceId }}</span>
            </div>
            <div class="detailItem">
              <span>截帧任务总数</span>：
              <span>{{ detailObj.frameCutNum}}</span>
            </div>
            <div class="detailItem">
              <span>最后上传时间</span>：
              <span>{{ detailObj.lastUpTime }}</span>
            </div>
            <div class="detailItem">
              <span>成功截帧数</span>：
              <span>{{ detailObj.successImageNum }}</span>
            </div>
            <div class="detailItem">
              <span>失败截帧数</span>：
              <!-- <span>{{detailObj.orderId}}</span> -->
              <span>{{ detailObj.loseImageNum }}</span>
            </div>
          </div>
          <div class="detailTitle">截帧时间列表</div>
          <div style="padding: 10px 20px">
            {{ timeLista }}
          </div>
          <div class="detailTitle">照片信息</div>
          <div style="text-align: right; padding: 0 50px">
            <el-button type="primary" @click="downImage">下载图片</el-button>
          </div>
          <div class="imageBox">
            <div class="imageItem" v-for="(item, index) in imgarr" :key="index">
              <!-- <img :src="item.url" alt="" @click="showBig(item)"> -->
              <el-image style="width: 200px; height: 150px" :src="item.url" :z-index="3040" :preview-src-list="imgUrlarr">
              </el-image>
              <p>{{ item.timeSteampStr }}</p>
              <!-- <p>{{ item.timeSteamp }}</p> -->
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 二级订单子任务 -->
      <secondlevelTable ref="secondlevelTable" @sureEvent="sureEvent"></secondlevelTable>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import search from "./com/search.vue";
import secondlevelTable from "./com/secondlevelTable.vue";
export default {
  components: {
    search,
    secondlevelTable,
  },
  props: {},
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      closedialog: false,
      centerDialogVisible: false,
      dialogDetail: false,
      pageSize: 10,
      pageTotal: 0,
      cpage: 1,
      tableData: [],
      detailObj: {},
      imgarr: [],
      imgUrlarr: [],
      searchForm: {},
      ruleForm: {
        model: "",
        photoNum: 30,
        secend: 5,
        btime: "",
        etime: "",
        deviceId: "",
        orderId: "",
        textarea: "",
        timeRage: [],
        channel: "",
      },
      rules: {
        photoNum: [
          { required: true, message: "数量不能为空", trigger: "blur" },
          { type: "number", message: "数量必须为数字值", trigger: "blur" },
        ],
        deviceId: [
          { required: true, message: "请输入设备ID", trigger: "blur" },
        ],
        orderId: [{ required: true, message: "请输入任务ID", trigger: "blur" }],
        channel: [
          { required: true, message: "请输入channel", trigger: "blur" },
        ],

        model: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        textarea: [
          { required: true, message: "请输入时间戳列表", trigger: "blur" },
        ],
      },
      timeLista: [], //截针时间列表
    };
  },
  watch: {},
  filters: {
    getLength(v) {
      return v ? v.length : 0;
    },
  },
  computed: {
    getlose() {
      if (this.detailObj.imageInfo) {
        return this.detailObj.imageInfo.length - this.imgarr.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    searchList(obj) {
      console.log(obj);
      this.searchForm = obj;
      this.getList(1);
    },
    //下载图片
    downImage() {
      let { detailObj, baseUrl } = this;
      //  cons
      // let href = `${baseUrl}/frame/downlownd?channel=${detailObj.channel}&orderId=${detailObj.orderId}&deviceId=${detailObj.deviceId}`;
      // let href = `${baseUrl}/frameCutOrder/download?id=${detailObj.id}`;
      let href = `${baseUrl}/frameCutOrder/download?id=${this.itemId}`;

      var a = document.createElement("a");
      a.href = href;
      a.id = "downImage";
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    request() {},
    getFirst() {
      this.cpage = 1;
      this.pageTotal = 0;
      this.getList(1);
    },
    addTask() {
      this.centerDialogVisible = true;
      this.ruleForm.orderId = new Date().getTime();
    },
    resetForm() {
      this.ruleForm = {
        model: "",
        photoNum: 30,
        secend: 5,
        deviceId: "",
        textarea: "",
        btime: "",
        etime: "",
        orderId: "",
        timeRage: [],
      };
      setTimeout(() => {
        this.$refs.ruleForm.clearValidate();
      }, 1000);
    },
    cancel() {
      this.centerDialogVisible = false;
      this.resetForm();
    },
    currentChange(num) {
      this.getList(num);
    },
    //获取任务列表
    getList(num) {
      let { baseUrl, searchForm } = this;
      console.log(searchForm);
      $.ajax({
        url: `${baseUrl}/frameCutOrder/pageList`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {
          channel: searchForm.channel, //渠道
          page: num,
          limit: this.pageSize,
          // ...searchForm,
          deviceId: searchForm.deviceId,
          orderId: searchForm.orderId,
          startTime: searchForm.timeRage ? searchForm.timeRage[0] : "",
          endTime: searchForm.timeRage ? searchForm.timeRage[1] : "",
        },
        success: (data) => {
          console.log(data.dataList);
          if (data.code == "00000") {
            this.pageTotal = data.totalCount;
            if (data.dataList) {
              data.dataList.map((item, index) => {
                item.receiveTime = dayjs(item.receiveTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
                if (item.issueTime != null) {
                  item.issueTime = dayjs(item.issueTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                }
                if (item.resultReceiveTime != null) {
                  item.resultReceiveTime = dayjs(item.resultReceiveTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                }
                //订单发送时间
                if (item.sendTime != null) {
                  item.sendTime = dayjs(item.sendTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                }

                // item.imageInfoStr = item.imageInfo.join(",");
                // if (imgInfo.length) {
                //     let newarr = imgInfo.map((a, i) => dayjs(a).format("YYYY-MM-DD HH:mm:ss"));
                //     item.imageInfoStr = newarr.join(",")
                // }
              });
              this.tableData = data.dataList;
            } else {
              this.tableData = [];
              this.pageTotal = 0;
            }
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    //下载整个订单的图片
    downloadBtn(item) {
      let { baseUrl } = this;
      let href = `${baseUrl}//frameCutOrder/downloadByTaskId?id=${item.id}`;

      var a = document.createElement("a");
      a.href = href;
      a.id = "downImage";
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getDetail(item) {
      let { baseUrl } = this;

      console.log(item);

      $.ajax({
        url: `${baseUrl}/frameCutOrder/detail`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {
          id: item.id,
        },
        success: (data) => {
          console.log(data);
          if (data.code == "00000") {
            let result = data.data;
            this.detailObj = result;
            console.log(this.detailObj);
            let arr = [];
            let urlArr = [];
            this.timeLista = result.timeList;
            //时间列表
            // if (result.timeList.length) {
            //   for (let i = 0; i < result.timeList.length; i++) {
            //     let item = result.timeList[i];
            //     this.timeLista.push(dayjs(item).format("YYYY-MM-DD HH:mm:ss"));
            //   }
            // }
            //图片信息
            if (result.imageInfo.length) {
              for (let i = 0; i < result.imageInfo.length; i++) {
                let item = result.imageInfo[i];
                let keys = Object.keys(item);
                if (keys.length) {
                  for (const key in item) {
                    let o = {
                      timeSteampStr: dayjs(+key).format("YYYY-MM-DD HH:mm:ss"),
                      timeSteamp: +key,
                      url: item[key],
                    };
                    arr.push(o);
                  }
                }
              }
            }
            //从小到大排序
            arr.sort((a, b) => a.timeSteamp - b.timeSteamp);
            arr.map((a, b) => {
              urlArr.push(a.url);
            });
            this.imgarr = arr;
            this.imgUrlarr = urlArr;
            // console.log(arr);
          } else {
            this.$message.warning("暂无详情");
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    sendTask() {
      let { baseUrl, ruleForm } = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            channel: ruleForm.channel,
            device_id: ruleForm.deviceId,
            order_id: ruleForm.orderId,

            image_info: "",
          };
          // console.log(ruleForm);
          if (ruleForm.channel == "baidu" && ruleForm.model == "1") {
            this.$message.error("请修改截帧模式");
            return;
          }
          let sen = "";
          let time = "";
          let arr = [];
          let reg = /^\d{13}$/;
          if (ruleForm.model != 3) {
            if (!reg.test(ruleForm.btime)) {
              this.$message.error("截帧开始时间格式错误");
              return;
            }
            if (!reg.test(ruleForm.stime)) {
              this.$message.error("截帧结束时间格式错误");
              return;
            }
            if (ruleForm.btime > ruleForm.stime) {
              this.$message.error("截帧时间反了");
              return;
            }
            params.btime = ruleForm.btime;
            params.etime = ruleForm.stime;
            sen = parseInt((params.etime - params.btime) / 1000);
            time = parseInt(params.btime / 1000);
            if (sen < ruleForm.photoNum) {
              this.$message("请将时间选长点");
              return;
            }
          }
          if (ruleForm.model == 1) {
            for (let i = 0; i < ruleForm.photoNum; i++) {
              let n = time + parseInt(Math.fround(Math.random() * sen));
              while (arr.indexOf(n) > -1) {
                n = time + parseInt(Math.fround(Math.random() * sen));
              }
              arr.push(n);
            }
            arr.sort((a, b) => {
              return a - b;
            });
          } else if (ruleForm.model == 2) {
            for (let i = 0; i < ruleForm.photoNum; i++) {
              time = time + ruleForm.secend;
              arr.push(time);
            }
          }
          if (ruleForm.model == 3) {
            if (!ruleForm.textarea) {
              this.$message.error("请输入截帧时间");
              return;
            }
            let newArr = ruleForm.textarea.split(",");
            let item = "";
            let flag = true;
            for (let i = 0; i < newArr.length; i++) {
              newArr[i] = parseInt(newArr[i]);
              if (!reg.test(newArr[i])) {
                flag = false;
                item = newArr[i];
                break;
              }
            }
            if (!flag) {
              this.$message.error(item + "输入的时间戳格式有问题");
              return;
            }
            newArr.sort((a, b) => a - b);
            params.image_info = newArr;
            params.btime = newArr[0];
            params.etime = newArr[newArr.length - 1];
          } else {
            let newArr = arr.map((item, index) => item * 1000);
            params.image_info = newArr;
          }
          params.image_info = params.image_info.join(",");
          params.etime = params.etime + 60 * 60 * 24 * 2 * 1000;
          $.ajax({
            url: `${baseUrl}/task/receive`,
            type: "POST",
            dataType: "JSON",
            contentType: "application/json",
            data: JSON.stringify(params),
            success: (data) => {
              if (data.code == "00000") {
                this.centerDialogVisible = false;
                setTimeout(() => {
                  this.resetForm();
                }, 1000);
                this.cpage = 1;
                this.getList(1);
                this.$message.success("新建成功");
              } else {
                this.$message.warning(data.message || "新建失败");
              }
            },
            error: (err) => {
              console.log(999999999);
            },
          });
        } else {
          return false;
        }
      });
    },
    checkItem(item) {
      // this.dialogDetail = true;
      // this.getDetail(item);
      console.log(item);
      this.$refs.secondlevelTable.showDialog(item.id);
    },
    sureEvent(item) {
      console.log(item);
      this.dialogDetail = true;
      this.getDetail(item);
      this.itemId = item.id;
    },
    showBig(item) {},
    closeDetail() {
      this.dialogDetail = false;
      this.imgarr = [];
    },
  },
  created() {
    this.getList(1);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#cutPhoto ::v-deep {
  .tableList {
    margin-top: 20px;
  }
  .curPhotoBody {
  }
  .flooterMenu {
    text-align: center;
  }
  .taskDetail {
    height: 500px;
    overflow-y: auto;
  }
  .phoHeader {
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    background: #409eff;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  .detailTitle {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .detailDesc {
    height: 130px;
  }
  .detailItem {
    width: 300px;
    float: left;
    padding: 10px 20px;
  }
  .imageBox {
    display: flex;
    flex-wrap: wrap;
  }
  .imageItem {
    width: 200px;
    padding: 20px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 6%);
  }
  .imageItem p {
    width: 200px;
    text-align: center;
  }
  .imageItem img {
    width: 100%;
    height: 100%;
  }
  .el-image-viewer__btn {
    color: #fff;
  }
}
</style>