<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-01 16:33:37
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-26 16:03:52
-->
<template>
  <div class="goodsSearch">
    <el-form>
      <el-form-item label="任务ID：">
        <el-input
          v-model="form.orderId"
          style="width: 400px"
          size="small"
          clearable
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
       <el-form-item label="渠道：">
        <el-input
          v-model="form.channel"
          style="width: 400px"
          size="small"
          clearable
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="起止时间：">
        <el-date-picker
          v-model="form.timeRage"
          size="small"
          type="datetimerange"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="设备ID：">
        <el-input
          v-model="form.deviceId"
          style="width: 180px"
          size="small"
          clearable
          placeholder="请输入内容"
        ></el-input>
        <el-button
          size="small"
          class="goodsSearchBtn"
          type="primary"
          @click="researchData()"
          >查询列表</el-button>
          <el-button
          size="small"
          class="goodsSearchBtn"
          type="primary"
          @click="addGood()"
          >新增任务</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      form:{
        orderId:"",
        channel:"",//渠道
        deviceId: "",
        timeRage:[]
      }
      
    };
  },
  watch: {},
  computed: {},
  methods: {
    researchData() {
      this.$emit("search",this.form)
    },
    addGood(){
      this.$emit("add")
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.goodsSearch ::v-deep {
  background: #fff;
  clear: both;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 0px;
  .el-radio-button__inner {
    border: none;
    width: 88px;
    padding: 10px 10px;
  }
  .el-form-item {
    display: inline-block;
    margin: 0;
    margin-left: 20px;
    .el-form-item__content{
      display: inline-block;
    }
  }
  .goodsSearchBtn{
    width: 80px;
    margin-left: 20px;
  }
}
</style>